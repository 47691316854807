<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="!isNew && !account"
        cols="12"
      >
        <i class="fas fa-spin fa-spinner"></i>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <material-card
          color="primary"
          :heading="pageTitle"
        >
          <v-card-text>
            <i
              v-if="!isNew && !account"
              class="fas fa-spin fa-spinner"
            ></i>
            <validation-observer v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.last_name').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.last_name"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.last_name')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.first_name').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.first_name"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.first_name')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|email"
                      :name="$t('forms.email').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.email"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.email')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="isNew ? 'required' : ''"
                      vid="password"
                      :name="$t('forms.password').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.password"
                        :error-messages="errors"
                        type="password"
                        outlined
                        :label="$t('forms.password')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="isNew && form.password ? 'required|confirmed:password' : ''"
                      :name="$t('forms.password_confirmation').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.password_confirmation"
                        :error-messages="errors"
                        type="password"
                        outlined
                        :label="$t('forms.password_confirmation')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.role').toLowerCase()"
                    >
                      <v-select
                        v-model="form.role"
                        :items="roles"
                        item-text="label"
                        item-value="id"
                        outlined
                        :error-messages="errors"
                        :label="$t('forms.role')"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/accounts"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import serverError from '../../mixins/serverError';
  export default {
    name: 'EluForm',

    mixins: [serverError],

    data: () => ({
      form: {
        first_name: null,
        last_name: null,
        email: null,
        role: 'admin',
        password: null,
        password_confirmation: null,
      },
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        account: 'account/getUser',
      }),
      isNew() {
        return !this.$route.params.id;
      },
      pageTitle() {
        return this.isNew ? this.$t('pageTitles.NewAccount') : this.$t('pageTitles.Editing') + this.account.full_name;
      },
      roles() {
        return [
          {
            id: 'admin',
            label: this.$t('role_admin'),
          },
          {
            id: 'redactor',
            label: this.$t('role_redactor'),
          },
        ];
      },
    },

    created() {
      if (!this.isNew) {
        this.$store.dispatch('account/fetchUser', this.$route.params.id)
          .then(() => {
            this.form = {
              ...this.account,
            };
          });
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { id, first_name, last_name, email, role, password, password_confirmation } = this.form
          const payload = { id, first_name, last_name, email, role, password, password_confirmation };
          const action = this.isNew ? 'account/createUser' : 'account/updateUser';
          const message = this.isNew ? this.$t('successfully_created') : this.$t('successfully_updated');
          this.$store.dispatch(action, payload)
            .then(() => {
              this.$toasted.success(message);
              this.$router.push('/accounts');
            })
            .catch(error => this.displayErrors(error));
        }
      },
    },
  }
</script>
