var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-forms-view","fluid":"","tag":"section"}},[_c('v-row',[(!_vm.isNew && !_vm.account)?_c('v-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fas fa-spin fa-spinner"})]):_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"primary","heading":_vm.pageTitle}},[_c('v-card-text',[(!_vm.isNew && !_vm.account)?_c('i',{staticClass:"fas fa-spin fa-spinner"}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.last_name').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.last_name')},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.first_name').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.first_name')},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('forms.email').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.isNew ? 'required' : '',"vid":"password","name":_vm.$t('forms.password').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"password","outlined":"","label":_vm.$t('forms.password')},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":_vm.isNew && _vm.form.password ? 'required|confirmed:password' : '',"name":_vm.$t('forms.password_confirmation').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"password","outlined":"","label":_vm.$t('forms.password_confirmation')},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.role').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.roles,"item-text":"label","item-value":"id","outlined":"","error-messages":errors,"label":_vm.$t('forms.role')},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"full-width d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"color":"gray","to":"/accounts"}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":_vm.processing ? 'gray' : 'success'}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.processing ? 'fas fa-spinner fa-spin' : 'fas fa-check')}}),_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }